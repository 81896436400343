<template>
    <v-app style="background: transparent" :class="{}">
        <!-- begin:: Content Head -->
        <KTSubheader
                v-if="nav.subheaderDisplay"
                v-bind:breadcrumbs="nav.breadcrumbs"
                v-bind:title="nav.pageTitle"
        />
        <!-- end:: Content Head -->



        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">

                <!--begin::Card-->
                <div class="card card-custom">
                    <div class="card-header flex-wrap border-0 pt-6 pb-0">
                        <div class="card-title">
                            <h3 class="card-label">用户列表
                                <span class="d-block text-muted pt-2 font-size-sm"></span>
                            </h3>
                        </div>
                        <div class="card-toolbar">

                            <!--begin::Button-->
                            <a href="javascript:;" class="btn btn-primary font-weight-bolder" @click="addUser()">
                                新增用户
                            </a>
                            <!--end::Button-->
                        </div>
                    </div>
                    <div class="card-body">
                        <!--begin: Search Form-->

                        <!--begin::Search Form-->
                        <div class="mb-7">
                            <div class="row align-items-center">
                                <div class="col-lg-9 col-xl-8">
                                    <div class="row align-items-center">
                                        <div class="col-md-4 my-2 my-md-0">
                                            <div class="input-icon">
                                                <input type="text" class="form-control" placeholder="用户名称" v-model="search_form.name">
                                                <span>
											    <i class="flaticon2-search-1 text-muted"></i>
											</span>
                                            </div>
                                        </div>
                                        <a href="#" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!--end::Search Form-->
                        <!--end: Search Form-->


                        <KTDatatable
                                v-bind:list="list"
                                v-bind:column="column"
                                v-bind:showSelect="false"
                                v-bind:showPagination="false"
                                @operateHandler="operateHandler"
                        ></KTDatatable>

                    </div>
                </div>
                <!--end::Card-->
            </div>
            <!--end::Container-->
        </div>

        <v-dialog v-model="dialog_show" persistent max-width="600px">
            <component
                    :key="dialog_id"
                    :title="dialog_title"
                    :visible="dialog_show"
                    :is="dialog_view"
                    :dialog_data="dialog_data"
                    @opeareResultHandler="opeareResultHandler"></component>
        </v-dialog>
    </v-app>
</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTDatatable from "@/view/content/tables/Datatable.vue";

    import addUser from '@/view/pages/adminUser/AddUser';
    import updateUser from '@/view/pages/adminUser/UpdateUser';
    import KTUtil from "@/assets/js/components/util";
    import {getUserList, deleteUser} from '@/api/user';
    import Swal from "sweetalert2";

    export default {
        name: "user_view",
        components: {
            KTSubheader,
            KTDatatable
        },
        created: function(){
            var _this = this;
            _this.searchList();
        },
        data() {
            return {
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/adminUser', 'title':'用户管理'}],
                    pageTitle: '系统'
                },
                list: [],
                column: [{
                    field: 'id',
                    title: '编号',
                    width: 60
                },{
                    field: 'username',
                    title: '用户名',
                    width: 150
                },{
                    field: 'role_name',
                    title: '用户角色',
                },{
                    field: 'nickname',
                    title: '用户昵称',
                },{
                    field: 'status',
                    title: '状态',
                    width: 60,
                    formatter: function(row) {
                        if(row.status == 1){
                            return '<span class="text-primary">正常</span>'
                        } else if(row.status == 0){
                            return '<span class="text-danger">禁用</span>'
                        } else if(row.status == 2){
                            return '<span class="text-warning">锁定</span>'
                        }
                    }
                },{
                    field: 'create_time',
                    title: '创建时间',
                    width: 200,
                    formatter: function(row) {
                        return KTUtil.timestamp2date(row.create_time);
                    }
                },{
                    field: 'operate',
                    title: '操作',
                    formatter: function(row){
                        let html = '' +
                            '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="修改">'+
                            '<i class="la la-edit icon-lg"></i>'+
                            '</a>'+
                            '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="删除">'+
                            '<i class="la la-trash-o icon-lg"></i>'+
                            '</a>';
                        return html;
                    }
                }],
                search_form: {
                    name: ''
                },
                page: {
                    currPage: 1,
                    limit: 15,
                    pageCount:0,
                    total:0,
                },
                dialog_id: 0,
                dialog_show: false,
                dialog_title: '',
                dialog_view: null,
                dialog_data: null
            }
        },
        methods: {
            searchList() {
                var _this = this;
                getUserList({'name': _this.search_form.name, 'page': _this.page.currPage, 'limit': _this.page.limit}).then(function(res){
                    if(res){
                        _this.list = res.response;
                    }
                });
            },
            operateHandler(clazz, row) {
                var _this = this;
                if(clazz.indexOf('la-edit') > -1){
                    //编辑操作
                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '编辑用户';
                    this.dialog_view = updateUser;
                    this.dialog_data = {
                        form_data: row
                    };
                } else if(clazz.indexOf('la-trash-o') > -1){
                    //删除操作
                    KTUtil.confirm('操作提示', '是否确认删除该条记录？', function(){
                        deleteUser({id: row.id}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '删除成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                }
            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
            addUser() {
                this.dialog_id = new Date().getTime();
                this.dialog_show = true;
                this.dialog_title = '新增用户';
                this.dialog_view = addUser;
                this.dialog_data = {

                };
            },
            opeareResultHandler(result) {
                if(result){
                    this.searchList();
                }
                this.dialog_show = false;
            }
        }
    }
</script>
