import request from './request';

export function getRuleList(params){
  return request({
    url: 'rule/getRuleList',
    method: 'post',
    data: params
  });
}

export function addRule(params){
  return request({
    url: 'rule/addRule',
    method: 'post',
    data: params
  });
}

export function editRule(params){
  return request({
    url: 'rule/editRule',
    method: 'post',
    data: params
  });
}

export function deleteRule(params){
  return request({
    url: 'rule/deleteRule',
    method: 'post',
    data: params
  });
}





