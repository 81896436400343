<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body">


                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">用户名 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入用户名" v-model="data_form.username" name="username">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">昵称 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入昵称" v-model="data_form.nickname" name="nickname">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">登录密码 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="password" class="form-control" placeholder="请输入登录密码" v-model="data_form.password" name="password">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">手机号码 :</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入手机号码" v-model="data_form.phone" name="phone">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">角色 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <b-form-select class="form-control" v-model="data_form.role_id" :options="role_list" placeholder="请选择角色权限" name="role_id">

                        </b-form-select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">状态 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6 col-form-label">
                        <div class="radio-inline">
                            <label class="radio">
                                <input type="radio" name="status" value="1" v-model="data_form.status"/>
                                <span></span>
                                正常
                            </label>
                            <label class="radio">
                                <input type="radio" name="status" value="0" v-model="data_form.status"/>
                                <span></span>
                                禁用
                            </label>
                            <label class="radio">
                                <input type="radio" name="status" value="2" v-model="data_form.status"/>
                                <span></span>
                                锁定
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {addRule} from '@/api/rule';
    import {getUserRoleList, editAdminUser} from '@/api/user';

    export default {
        name: "user_add",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {
            var _this = this;
            getUserRoleList({}).then(function(res){
                if(res){
                    _this.role_list = [];
                    if(res && res.response){
                        for(var i = 0;i < res.response.length;i++){
                            _this.role_list.push({
                                'value': res.response[i].id,
                                'text': res.response[i].role_name
                            });
                        }
                    }
                }
            });
        },
        components :{

        },
        data() {
            return {
                data_form:{
                    id: this.dialog_data.form_data.id,
                    username: this.dialog_data.form_data.username,
                    nickname: this.dialog_data.form_data.nickname,
                    password: '',
                    phone: this.dialog_data.form_data.phone,
                    role_id: this.dialog_data.form_data.role_id,
                    status: this.dialog_data.form_data.status
                },
                role_list: [],
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    username: {
                        validators: {
                            notEmpty: {
                                message: "用户名称不能为空"
                            }
                        }
                    },
                    nickname: {
                        validators: {
                            notEmpty: {
                                message: "用户昵称不能为空"
                            }
                        }
                    },
                    phone: {
                        validators: {
                            regexp: {
                                regexp: /^1\d{10}$/,
                                message: '手机号格式错误'
                            }
                        }
                    },
                    role_id: {
                        validators: {
                            notEmpty: {
                                message: "请选择用户角色"
                            }
                        }
                    },
                    status: {
                        validators: {
                            notEmpty: {
                                message: "请选择用户状态"
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                editAdminUser(_this.data_form).then(function (res){
                    if (res) {

                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            menuTreeSelect(array) {
                this.menuSelectNode = [];
                var index = array.length - 1;
                this.menuSelectNode.push(array[index]);
                this.data_form.menu_id = this.menuSelectNode[0];
                this.$refs["menu_id_input"].parentElement.lastChild.remove();
            }
        }
    }
</script>
